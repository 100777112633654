<template>
    <nav id="navigation" class="row">
        <div class="col-xs-2"  @click="navigationBack">
            <i class="el-icon-arrow-left" v-if="isCanBack"></i>
        </div>
        <div class="col-xs-8">
            <span>{{title}}</span>
        </div>
    </nav>
</template>

<script>
    export default {
        name: "navigation",
        props: {
            title: String
        },
        data: function () {
            return {
                isCanBack: true,
            }
        },
        // watch: {
        //     $route(to, from) {
        //         let topPage = ['','CompetiH5', 'ManagementCenterH5', 'PersonalInfoH5'];
        //         let toName = to.name;
        //         let isNeedShow = true;
        //         for (let i=0;i<topPage.length;i++) {
        //             if (toName === topPage[i]) {
        //                 isNeedShow = true;
        //                 break;
        //             }
        //         }
        //         this.isCanBack = isNeedShow;
        //         this.$emit('need-hidden-tab-bar', isNeedShow);
        //     }
        // },
        methods: {
            navigationBack() {
                if(this.$route.path === '/SingleItemH5'){
                    this.$router.push('/CompetiH5')
                }else{
                    this.$router.go(-1);
                }
            }
        }
    }
</script>

<style scoped>
    .row {
        background-color: #fff;
        width: 100%;
        height: 44px;
        border: solid rgb(210, 210, 210);
        border-width: 0 0 1px 0;
        position: fixed;
        margin: auto;
        top: 0;
		display: flex;
		z-index: 999;
    }

	.col-xs-2{
		width: 10%;
		font-size: 1.2rem;
		font-weight: 600;
	}
	.col-xs-8{
		width: 80%;
		overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
    div {
        text-align: center;
        line-height: 44px;
    }

    span {
        font-size: 14px;
        font-weight: bold;
    }

</style>